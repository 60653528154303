footer {
  &.print {
    display: none;
    border-top: 1px solid $color-grey;
    .copyright {
      text-align: center;
    }
  }
  @media print {
    &.print {
      display: block;
    }
  }
}
