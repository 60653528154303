header {
  &.print {
    display: none;
    padding: 20px 0;
    border-bottom: 4px solid $color-grey;
    background: none;
    height: auto !important;
    overflow: visible;
    position: relative;
    .info {
      width: 300px;
      margin: 0 0 0 auto;
      table {
        border-collapse: collapse;
        margin: 0 0 0 auto;
        tr {
          &:nth-child(odd) {
            background: none;
          }
          th {
            padding: 0px;
            font-weight: bold;
            color: $color-black;
            text-align: left;
            line-height: 1rem;
            font-size: 1rem;
          }
          td {
            padding: 2px;
            color: $color-black;
            text-align: right;
            line-height: 1rem;
            font-size: 1rem;
          }
          td[colspan="2"] {
            text-align: left;
            font-style: italic;
          }
        }
      }
    }
  }
  @media print {
    &.print {
      display: block;
    }
  }
}
@media print {
  main {   
    .hero-banner,.order-success,
    #banner-container {
      display: none;
    }
    .grid-list-with-text .container .row div[class^=col-].grid-list-text-block-item:nth-child(even) {
      border-left: none;
  }
  }
}

@media print{@page{margin:2cm;}}
