// Color Codes
$color-black:#000000;
$color-white:#ffffff;
$color-orange:#ED5C2F;
$color-blue: #0057B8;
$secondary-blue: #009CDE;
$color-grey: #6C6F70;
$light-grey: #F2F2F2;
$medium-grey: #D3D3D3;




// Color Names with sections
$header-bg: $color-black;
$header-color: $color-white;




